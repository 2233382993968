<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
// @ts-nocheck
import type { InputsTextFieldsProps } from './InputsTextFields.props'
import type { FormKitNode } from '@formkit/core'

const props = withDefaults(defineProps<InputsTextFieldsProps>(), {
  type: 'text',
  invalidValue: false,
  validationVisibility: 'blur', //same as formkit default
  mask: '',
})

const emit = defineEmits<{
  (e: 'confirm', value?: string): void | string
  (e: 'remove'): void
  (e: 'click-action-button'): void
  (e: 'valid', value: boolean): void
}>()

const formkitRef = ref<null | { node: FormKitNode }>(null)

const node = computed(() => {
  return formkitRef.value?.node
})
//removes spaces from text input
onUpdated(() => {
  const formTextlInput = formkitRef.value?.node.value as unknown as string
  if (
    (formTextlInput && props.name === 'phoneNumber') ||
    (formTextlInput && props.type === 'email')
  ) {
    node.value?.input(formTextlInput.trim().replace(/ /g, ''))
  }
})

const deleteValue = () => {
  node.value?.reset()
  emit('remove')
}

const isValidEmail = (validState: boolean) => {
  if (!props.invalidValue && validState) {
    emit('valid', true)
    return true
  }
  emit('valid', false)
  return false
}
</script>

<template>
  
<FormKitLazyProvider config-file="true">
<FormKit
    ref="formkitRef"
    :mask="mask"
    :tokens="tokens"
    :type="type"
    :label="label"
    :name="name"
    :disabled="disabled"
    :readonly="readonly"
    :help="help"
    :errors="errors"
    :validation="validation"
    :validation-rules="validationRules"
    :validation-messages="validationMessages"
    :validation-visibility="validationVisibility"
    :floating-label="true"
  >
    <template #suffixIcon="{ state, value }">
      <div class="flex items-center gap-x-2">
        <button
          v-if="
            (type === 'email' && isValidEmail(state.valid) && confirmText) ||
            (type !== 'email' && confirmText)
          "
          class="text-medium-7 uppercase"
          @click="emit('confirm')"
        >
          {{ confirmText }}
        </button>

        <div
          v-if="
            type !== 'email' &&
            !state?.empty &&
            (showConfirmButton || showRemoveButton)
          "
          class="flex items-center"
        >
          <button
            v-if="showConfirmButton && !invalidValue && state.valid"
            :aria-label="$ts('accessibility.confirm')"
            @click="emit('confirm')"
          >
            <DefaultIconsNext aria-hidden="true" class="h-5 w-5" />
          </button>

          <button
            v-if="showRemoveButton"
            type="button"
            :aria-label="$ts('accessibility.clear')"
            @click.prevent="deleteValue"
            @mousedown.prevent
          >
            <DefaultIconsClose
              class="h-5 w-5"
              aria-hidden="true"
              :class="[
                isCouponCode
                  ? 'text-neutral-black'
                  : 'text-primitives-grey-150',
              ]"
            />
          </button>
        </div>

        <button
          v-if="
            type === 'email' && isValidEmail(state.valid) && showConfirmButton
          "
          :aria-label="$ts('accessibility.confirmEmail')"
          type="button"
          @click="emit('confirm', value)"
        >
          <DefaultIconsNext class="h-5 w-5" aria-hidden="true" />
        </button>

        <DefaultIconsWarning
          v-if="
            (showFeedbackIcons &&
              state?.rules &&
              !state.valid &&
              state?.blurred) ||
            invalidValue
          "
          aria-live="polite"
          :aria-label="$ts('accessibility.invalidValue')"
          class="text-primitives-red h-5 w-5"
        />
      </div>
    </template>
    <template #help>
      <div></div>
    </template>
    <template #messages="{ id, messages }">
      <div class="mt-2 flex items-baseline justify-between gap-1">
        <div class="flex flex-col gap-1">
          <span
            v-if="help && !Object.keys(messages).length"
            :id="`help-${id}`"
            aria-live="assertive"
            class="text-light-8 ml-2"
            :class="{ 'text-primitives-red': isHelpTextRed }"
          >
            {{ help }}
          </span>
          <ul
            v-if="Object.keys(messages).length"
            class="text-primitives-red text-light-8 pointer-events-none ml-2"
          >
            <li
              v-for="message in messages"
              :id="`${id}-${message.key}`"
              :key="message.key"
              aria-live="assertive"
            >
              {{ message.value }}
            </li>
          </ul>
        </div>
        <div
          v-if="actionText || extraActionText"
          class="ml-auto flex shrink-0 justify-center gap-1"
        >
          <AtomsCta
            v-if="actionText"
            anatomy="link"
            class="text-link-8"
            :button-attrs="{ type: 'button' }"
            :class="{
              'pointer-events-none opacity-20': isActionTextDisabled,
            }"
            :aria-haspopup="isActionTextOpeningDialog ? 'dialog' : undefined"
            @click="emit('click-action-button')"
          >
            <template #label>
              {{ actionText }}
            </template>
          </AtomsCta>
          <span v-if="extraActionText" class="text-book-8">
            {{ extraActionText }}
          </span>
        </div>
      </div>
    </template>
  </FormKit>
</FormKitLazyProvider>

</template>

<style lang="scss" src="@design-system/assets/css/floatingLabelFormkit.scss" />
